import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { OAuthModule } from 'angular-oauth2-oidc';
import { MaterialModule } from '../material/material.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ContextService } from '../services/config-context.service';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MaterialModule,
    OAuthModule.forRoot({
      resourceServer: { sendAccessToken: true, allowedUrls: ['http'] }
    }),
    AppRoutingModule
  ],
  providers: [ContextService],
  bootstrap: [AppComponent]
})
export class AppModule {}
