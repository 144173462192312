import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

@Injectable({
  providedIn: 'root'
})
export class ApplicationInsightsService {
  private appInsights: ApplicationInsights;

  constructor() {}

  initialize(instrumentationKey: string) {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: instrumentationKey,
        autoTrackPageVisitTime: true,
        enableCorsCorrelation: true,
        loggingLevelTelemetry: 2,
        isBrowserLinkTrackingEnabled: true,
        enableAutoRouteTracking: true
      }
    });

    this.appInsights.loadAppInsights();
  }

  setUserId(userId: string) {
    this.appInsights.setAuthenticatedUserContext(userId);
  }

  clearUserId() {
    this.appInsights.clearAuthenticatedUserContext();
  }
}
