import { Component, OnInit } from '@angular/core';
import { OAuthService, JwksValidationHandler } from 'angular-oauth2-oidc';

import { ContextService } from '../services/config-context.service';
import {
  SubscriptionService,
  OAuthHelpService,
  RoutingHelpService
} from '@coop/common-lib';
import { ApplicationInsightsService } from '../services/application-insights.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [SubscriptionService, OAuthHelpService]
})
export class AppComponent implements OnInit {
  title = 'Universal Admin';
  authenticated = false;
  userPersonalNumber: string;
  userName: string;

  constructor(
    private contextService: ContextService,
    private oauthService: OAuthService,
    private oauthHelpService: OAuthHelpService,
    private subscriptionService: SubscriptionService,
    private applicationInsightsService: ApplicationInsightsService,
    private routingHelpService: RoutingHelpService
  ) {}

  ngOnInit(): void {
    this.observeContextConfig();
  }

  login(): void {
    this.oauthService.initImplicitFlow();
  }

  logout(): void {
    this.applicationInsightsService.clearUserId();
    this.oauthService.logOut();
  }

  private observeContextConfig(): void {
    this.subscriptionService
      .takeUntilDestroyed(this.contextService.contextRplSubj)
      .subscribe(context => {
        if (context) {
          const oauthService = this.oauthService;
          this.applicationInsightsService.initialize(
            context.instrumentationKey
          );

          oauthService.configure({
            ...context.authConfig,
            requireHttps: false,
            requestAccessToken: true,
            silentRefreshRedirectUri: `${window.location.origin}/silent-refresh.html`,
            clearHashAfterLogin: false
          });

          oauthService.setupAutomaticSilentRefresh();

          oauthService.tokenValidationHandler = new JwksValidationHandler();

          oauthService
            .loadDiscoveryDocumentAndLogin({
              onTokenReceived: () => {
                this.routingHelpService.navigateToCurrentUrl();
              }
            })
            .then(authenticated => {
              this.oauthHelpService.authUpdate(authenticated);
              this.authenticated = authenticated;
              this.userPersonalNumber = oauthService.getIdentityClaims()['sub'];
              this.userName = oauthService.getIdentityClaims()['name'];
              this.applicationInsightsService.setUserId(
                this.userPersonalNumber
              );
            });
        }
      });
  }
}
