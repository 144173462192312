import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ReplaySubject } from 'rxjs';
import { AppConfig } from '../models/config.model';

@Injectable({
  providedIn: 'root'
})
export class ContextService {
  contextRplSubj = new ReplaySubject<AppConfig>(1);

  constructor(httpClient: HttpClient) {
    httpClient
      .get<AppConfig>('config/context.json')
      .subscribe(this.contextRplSubj);
  }
}
